import {useAuthStore} from '~/store/authStore'

export default defineNuxtRouteMiddleware(async (to, _) => {
    const cookieKey = to.path.startsWith('/tablet') ? 'tablet_token' : 'token'
    const token = useCookie(cookieKey).value
    const localizePath = useLocalePath()
    if (token === undefined) {
        return navigateTo(localizePath('/auth/login'))
    }

    const authStore = useAuthStore()
    const user = await authStore.getUser()

    if (!user) {
        return navigateTo(localizePath('/auth/login'))
    }
})
